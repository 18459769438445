/* @import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;600;700;800&display=swap");

body {
    background-color: #f1f1f1 !important;
    /* font-family: "Quicksand", sans-serif !important; */
    font-family: "Dosis", sans-serif !important;
    font-weight: 300;
    line-height: 1.5;
}

.light-bg {
    color: #f1f1f1 !important;
}

.theme-bg {
    background-color: #892cdc !important;
}

.dark-bg {
    background-color: #000000 !important;
}

.theme-text {
    color: #892cdc !important;
}

.btn-outline-purple {
    --bs-btn-color: #892cdc !important;
    --bs-btn-border-color: #892cdc !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #892cdc !important;
    --bs-btn-hover-border-color: #892cdc !important;
    --bs-btn-focus-shadow-rgb: 33, 37, 41;
    --bs-btn-active-color: #fff !important;
    --bs-btn-active-bg: #892cdc !important;
    --bs-btn-active-border-color: #892cdc !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #892cdc !important;
    --bs-btn-disabled-bg: transparent;
    --bs-gradient: none;
}

.none-a {
    text-decoration: none;
}

.none-a:hover {
    font-weight: 600 !important;
}

.nav-item:hover {
    cursor: pointer !important;
}

.active-tab {
    background-color: #892cdc;
    color: white;
}

.list-group-item:hover {
    cursor: pointer !important;
}

.MuiPagination-ul {
    justify-content: center !important;
}

.hover-effect:hover {
    cursor: pointer;
}

.disabled-effect {
    background-color: #cccccc !important;
    border-color: #999999 !important;
    color: #666666 !important;
}

/* loading */

.custom-loading ul {
    /* position: absolute;
       top: 30%;
       left: 50%; */
    transform: translate(-50%, 50%);
    margin: 0;
    padding: 0;
    display: flex;
}

.custom-loading ul li {
    list-style: none;
    width: 20px;
    height: 20px;
    background: #000;
    margin: 0 5px;
    border-radius: 50%;
    animation: animate 1.4s linear infinite;
}

@keyframes animate {
    0% {
        transform: translateY(0);
    }

    60% {
        transform: translateY(0);
    }

    80% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

.custom-loading ul li:nth-child(1) {
    animation-delay: -1.2s;
}

.custom-loading ul li:nth-child(2) {
    animation-delay: -1s;
}

.custom-loading ul li:nth-child(3) {
    animation-delay: -0.8s;
}

.custom-loading ul li:nth-child(4) {
    animation-delay: -0.6s;
}

/* === USER DASHBOARD === */

.card-ud {
    background-color: #fff;
    border-radius: 10px;
    border: none;
    position: relative;
    margin-bottom: 30px;
    /* box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
        0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
        0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
        0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1); */
}
.l-bg-cherry {
    background: linear-gradient(90deg, #8e2e1d, rgba(240, 32, 32, 0.604)) !important;
    color: #fff;
}

.l-bg-blue-dark {
    background: linear-gradient(90deg, #373b44, #4286f4) !important;
    color: #fff;
}

.l-bg-green-dark {
    background: linear-gradient(90deg, #0a504a, #38ef7d) !important;
    color: #fff;
}

.l-bg-orange-dark {
    background: linear-gradient(90deg, #a86008, #ffba56) !important;
    color: #fff;
}

.card-ud .card-statistic-3 .card-icon-large .fas,
.card-ud .card-statistic-3 .card-icon-large .far,
.card-ud .card-statistic-3 .card-icon-large .fab,
.card-ud .card-statistic-3 .card-icon-large .fal {
    font-size: 110px;
}

.card-ud .card-statistic-3 .card-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0.1;
}

.l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
}

.l-bg-green {
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
    color: #fff;
}

.l-bg-orange {
    background-color: #FBAB7E;
    background-image: linear-gradient(90deg, #FBAB7E 0%, #F7CE68 100%) !important;
    color: #fff;
}

.l-bg-purple {
    background-image: linear-gradient(90deg, #700aca 0%, #8f4fdea4 100%);
    color: #fff;
}

.l-bg-pink {
    background-image: linear-gradient(90deg, #572649 0%,  rgba(203, 26, 206, 0.695) 100%);
    color: #fff;
}